@import 'config.scss';

.NewCta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin-left: auto;
  margin-right: auto;
  background-color: $blue-800;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 80px;
  width: calc(100% - 32px);

  @include breakpoint($md) {
    padding-top: 64px;
    padding-bottom: 48px;
    margin-top: 136px;
  }
}

.contentWrapper {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 16px;
  color: white !important;
}

.text {
  margin-bottom: 24px;

  @include breakpoint($sm) {
    margin-bottom: 36px;
    color: $grey-100 !important;
  }
}

.image {
  position: absolute;
  width: auto;
  max-width: 24%;
  max-height: 50%;
}

.imageContainer {
  max-height: 100%;
}

.imageTopLeft {
  top: 0;
  left: 0;
}

.imageTopRight {
  top: 0;
  right: 0;
}

.imageBottomRight {
  bottom: 0;
  right: 0;
}

.imageBottomLeft {
  bottom: 0;
  left: 0;
}